// import '../styles/newHome.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import '../styles/newHome.css';
import DailyChart from '../components/home page/DailyChart';
import EntryResults from '../components/home page/EntryResults';
import YestInfo from '../components/home page/YestInfo';
import Loading from '../components/Loading';

export default function Home() {
  const [ data, setData ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  useEffect( () => {
    async function getData() {
      setLoading(true)
      try {
        axios({
          method: 'get',
          url: 'https://ax-app-746b7eed1e15.herokuapp.com/api/home-data',
          // url: 'http://localhost:4000/api/home-data'
        }).then(function(response) {
          setData(response);
        })
      } catch(error) {
        console.log(error)
      }
    setLoading(false)
    };
    getData();
  }, [] );
  return !data.data ? <Loading/> : (
    <div className='homepage'>
      <div className='dailyContainer'>
        <div className='leftCol'>
          <EntryResults today={data.data.today.fuelsVolume} previous={data.data.previous.fuelsVolume} props={{name: 'liters', title: 'Litraż sumarycznie', item: 'l'}}/>
          <EntryResults today={data.data.today.nettoSale} previous={data.data.previous.nettoSale} props={{name: 'cash', title: 'Sprzedaż paliw netto', item: 'zł'}}/>
          <EntryResults today={data.data.today.profit} previous={data.data.previous.profit} props={{name: 'profit', title: 'Marża operacyjna', item: 'zł'}}/> 
        </div>
        <div className='rightCol'>
          <DailyChart days={data.data.today.days} fuels={data.data.today.fuels} prevFuels={data.data.previous.fuels}/>
        </div>
      </div>
      <div className='yesterdayContainer'>
        <hr/>
        <YestInfo/>
      </div>
    </div>
  )
}