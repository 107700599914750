import { useState, useEffect } from "react";
import axios from 'axios';
import Tanks from "./Tanks";
import Loading from "../Loading";

export default function GetStorage() {
    const [ data, setData ] = useState();
    useEffect(() => {
        async function getData() {
            try {
                axios({
                    method: 'get',
                    // url: 'http://localhost:4000/api/fuel-storage',
                    url: "https://ax-app-746b7eed1e15.herokuapp.com/api/fuel-storage"
                }).then(function(response) {
                    setData(response)
                });
            } catch(error) { console.log(error) }
        };
        getData();
    }, []);

    return (
        <div className="fuelStorage">
            <h2>Stany zbiorników</h2>
            {!data ? <Loading/> : <div className="tankWrap"><Tanks data={data.data}/></div>}
        </div>
    )
}