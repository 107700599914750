export default function ShopGroupDetailsSummaries(props) {
    const data = props.data;
    function toPLN(number) {
        const formattedNumber = number.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedNumber;
    };
    return (
        <div className='summaries'>
            <div>
                <h5>Ilość:</h5>
                <h6>{data.qty}</h6>
            </div>
            <div>
                <h5>Sprzedaż netto:</h5>
                <h6>{toPLN(data.netto)}</h6>
            </div>
            <div>
                <h5>Sprzedaż brutto:</h5>
                <h6>{toPLN(data.brutto)}</h6>
            </div>
            <div>
                <h5>Marża zł:</h5>
                <h6>{toPLN(data.profit)}</h6>
            </div>
            <div>
                <h5>Marża:</h5>
                <h6>{((data.profit / data.netto) * 100).toFixed(2)} %</h6>
            </div>
        </div>
    )
}