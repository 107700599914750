import Loading from "../Loading";

export default function EntryResults(props) {
    function toPLN(number) {
        const formattedNumber = number.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedNumber;
    };
    return !props ? <Loading/> : (
        <div>
            <p>{props.props.title}</p>
            <h2>{props.props.item === 'zł' ? toPLN(props.today) : props.today.toFixed(2) + ' ' + props.props.item}</h2>
            <h6><span style={props.previous > props.today ? {color: 'red'} : {color: 'green'}}>{(((props.today * 100) / props.previous) - 100).toFixed(2)}%</span> niż w poprzednim tygodniu</h6>
        </div>
    )
}