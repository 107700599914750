export default function RaportDetails(props) {
    function formatDate(date) {
        const data = new Date(date)
        const year = data.getFullYear();
        const month = String(data.getMonth() + 1).padStart(2, '0');
        const day = String(data.getDate()).padStart(2, '0');
        const hour = String(data.getHours()).padStart(2, '0');
        const minutes = String(data.getMinutes()).padStart(2, '0');
        return `${hour}:${minutes} ${day}.${month}.${year}`
    };
    return (
        <div className="raportDetails">
            <div>
                <h6><span>Od:</span> {formatDate(props.data.start)}</h6>
            </div>
            <div>
                <h6><span>Do:</span> {formatDate(props.data.stop)}</h6>
            </div>
            <div>
                <h6><span>Dokumenty:</span> {props.data.docs.join(', ')}</h6>
            </div>
            <div>
                <h6><span>Kody produktów:</span> {props.data.product.join(', ')}</h6>
            </div>
        </div>
    )
}