import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import '../styles/login.css';
import logo from '../assets/AZAREX_logo_BLUE.png';
 
const Login = () => {
 const navigate = useNavigate();
 const location = useLocation();
 
 const { user, fetchUser, emailPasswordLogin } = useContext(UserContext);
 
 const [form, setForm] = useState({
   email: "",
   password: ""
 });
 
 const onFormInputChange = (event) => {
   const { name, value } = event.target;
   setForm({ ...form, [name]: value });
 };
 
 const redirectNow = () => {
   const redirectTo = location.search.replace("?redirectTo=", "");
   navigate(redirectTo ? redirectTo : "/");
 }
 const loadUser = async () => {
   if (!user) {
     const fetchedUser = await fetchUser();
     if (fetchedUser) {
       redirectNow();
     }
   }
 }
 useEffect(() => {
   loadUser(); 
 }, []);
 const onSubmit = async (event) => {
   event.preventDefault();
   try {
     const user = await emailPasswordLogin(form.email, form.password);
     if (user) {
       redirectNow();
     }
   } catch (error) {
       if (error.statusCode === 401) { 
          alert("Invalid username/password. Try again!");
      } else {
        console.log(error.statusCode);
      }
   }
 };
 
 return (
 <div className="loginpage">
    <form>
        <img src={logo} alt="logo"/>
        <p>Manager App</p>
        <input
            label="Email"
            type="email"
            name="email"
            value={form.email}
            onChange={onFormInputChange}
        />
        <input
            label="Password"
            type="password"
            name="password"
            value={form.password}
            onChange={onFormInputChange}
        />
        <button onClick={onSubmit}>
            Zaloguj się
        </button>
    </form>
 </div>
 )
}
 
export default Login;