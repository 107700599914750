import RaportCircleChart from "./RaportCircleChart";

export default function RaportSummary(props) {
    const data = props.data;
    function toPLN(number) {
        const formattedNumber = number.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedNumber;
    }
    return (
        <div className="raportSummary">
            <div className="circleChart">
                <RaportCircleChart data={data.products}/>
            </div>
            <div className="summary">
                <div className="keySummaries" style={{backgroundColor: '#e9ecef'}}>
                    <p>Wolumen sprzedaży</p>
                    <h2>{(data.saleVolume).toFixed(2)}</h2>
                </div>
                <div className="keySummaries" style={{backgroundColor: '#f8f9fa'}}>
                    <p>Zysk operacyjny</p>
                    <h2>{toPLN(data.saleProfit)}</h2>
                </div>
                <div>
                    <p>Sprzedaż netto</p>
                    <h2>{toPLN(data.saleNetto)}</h2>
                </div>
                <div>
                    <p>Sprzedaż vat</p>
                    <h2>{toPLN(data.saleVat)}</h2>
                </div>
                <div>
                    <p>Sprzedaż brutto</p>
                    <h2>{toPLN(data.saleBrutto)}</h2>
                </div>
            </div>
        </div>
    )
}