import { useState, useEffect } from "react";
import '../../styles/pricesoverview.css';
import axios from 'axios';
import PricesChart from "./PricesChart";
import Loading from '../Loading';

export default function PricesOverview() {
    const [ station, setStation ] = useState(1);
    const [ data, setData ] = useState();
    const products = [
        { item: 4243, name: 'ON', color: '#252525' },
        { item: 4241, name: 'PB95', color: '#49a665' },
        { item: 4244, name: 'LPG', color: '#a64949' },
        { item: 4245, name: 'AdBlue', color: '#496da6' }
    ];
    const findProductName = (item) => {
        const product = products.find(product => product.item === item);
        return product ? product.name : 'PPaliw';
    };
    const findProductColor = (item) => {
        const product = products.find(product => product.item === item);
        return product ? product.color : '#252525';
    };
    useEffect(() => {
        async function getPrices() {
            try {
                axios({
                    method: 'post',
                    url: "https://ax-app-746b7eed1e15.herokuapp.com/api/fuel-prices",
                    // url: 'https://ax-app--manager-7a0e131a3acf.herokuapp.com/api/fuel-prices',
                    // url: 'http://localhost:4000/api/fuel-prices',
                    data: { station }
                }).then(function(response) {
                    setData(response)
                });
            } catch (error) { console.log(error) }
        };
        getPrices();
    }, [ station ] );
    const Products = () => {
        return data && data.data.short.map(record => 
            <div className="fuelPrice" key={record._id}>
                <h6 style={{borderBottom: `2px solid ${findProductColor(record.POZTOWID)}`, color: findProductColor(record.POZTOWID)}}>{findProductName(record.POZTOWID)}</h6>
                <h2>{(record.CENASJS).toFixed(2)} zł</h2>
                {/* <p>{`${new Date(record.DATAPOWSTANIA).getHours() < 10 ? '0' + new Date(record.DATAPOWSTANIA).getHours() : new Date(record.DATAPOWSTANIA).getHours() }:${new Date(record.DATAPOWSTANIA).getMinutes() < 10 ? '0' + new Date(record.DATAPOWSTANIA).getMinutes() : new Date(record.DATAPOWSTANIA).getMinutes() } ${new Date(record.DATAPOWSTANIA).getDate() < 10 ? '0' + new Date(record.DATAPOWSTANIA).getDate() : new Date(record.DATAPOWSTANIA).getDate() }.${(new Date(record.DATAPOWSTANIA).getMonth() + 1) < 10 ? '0' + (new Date(record.DATAPOWSTANIA).getMonth() + 1) : new Date(record.DATAPOWSTANIA).getMonth() +1 }.${new Date(record.DATAPOWSTANIA).getFullYear()}`}</p> */}
            </div>
        )
    };
    return (
        <div className="pricesOverview">
            <div className="pricesTitle">
                <h1>Ceny paliw</h1>
                <select defaultValue={1} onChange={ e => {setStation(e.target.value)} }>
                    <option value={0} disabled>Wszystkie stacje</option>
                    <option value={1}>Świdnica, Towarowa 24</option>
                    <option value={2} disabled>Lubin, Ścinawska 20b</option>
                </select>
            </div>
            <div className="pricesContainer">
                { !data ? <Loading/> : <Products/>}
            </div>
            { !data ? <Loading/> : <PricesChart data={data.data.long}/>}
        </div>        
    );
}