import { Link } from "react-router-dom";

export default function ShopProductGroups(props) {
    const data = props.data;
    const docs = props.docs;
    function toPLN(number) {
        const formattedNumber = number.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedNumber;
    };
    function shortenString(str) {
        if (str.length > 12) {
          return str.slice(0, 12) + '...';
        }
        return str;
    };
    return data && data[0].map(item => 
        <Link to={`/shop-raport/group/${item.id}`} state={{ docs: docs.filter(element => element.groupID === item.id), data: item }} key={item.name}>
            <h5>{shortenString(item.name.substring(3))}</h5>
            <h6><span>Ilość: </span>{item.qty}</h6>
            <h6><span>Brutto: </span>{toPLN(item.brutto)}</h6>
            <h6><span>Marża śr: </span>{((item.profit / item.netto) * 100).toFixed(2)}%</h6>
            <h6><span>Marża zł: </span>{toPLN(item.profit)}</h6>
            <p>Raport +</p>
        </Link> 
    )
}