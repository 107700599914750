import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );

export default function ShopHours(props) {
    const data = props.data;
    const screen = window.matchMedia('(max-width: 767px)').matches;
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: screen ? {position: 'bottom'} : {position: 'right'}, title: { display: false, } },
    };
    const bg = [
        '#081C15',
        '#1B4332',
        '#2D6A4F',
        '#40916C',
        '#52B788',
        '#74C69D',
        '#95D5B2',
        '#B7E4C7',
        '#D8F3DC',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
        '#f0f7f2',
    ];
    for(const i in data) {
        data[i].backgroundColor = bg[i]
    };
    const chartData = {
        labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
        datasets: data && data.map(item => ({
            label: item.groupName.substring(3),
            data: item.qty,
            backgroundColor: item.backgroundColor,
            stack: item.stack
        }))
    };
    return <Bar options={chartOptions} data={chartData}/>
}