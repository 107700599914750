import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );


export default function RaportTimeLine(props) {
    const data = props.data;
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { position: 'top', }, title: { display: false, } },
    };
    const chartData = {
        labels: data && data.map(item =>item.date),
        datasets: [
            {
              label: 'Paliwa',
              data: data.length !== 0 && data.map(item => item.qty),
              backgroundColor: '#fff',
              fill: false,
              borderColor: '#a3a3a3',
              tension: 0.4, 
            },
            {
              label: 'ON',
              data: data.length !== 0 && data.map(item => item.ONQty),
              backgroundColor: '#fff',
              fill: false,
              borderColor: '#252525',
              tension: 0.4, 
            },
            {
              label: 'PB95',
              data: data.length !== 0 && data.map(item => item.PBQty),
              backgroundColor: '#fff',
              fill: false,
              borderColor: '#48965d',
              tension: 0.4, 
            },
            {
              label: 'LPG',
              data: data.length !== 0 && data.map(item => item.LPGQty),
              backgroundColor: '#fff',
              fill: false,
              borderColor: '#964850',
              tension: 0.4, 
            },
            {
              label: 'AdBlue',
              data: data.length !== 0 && data.map(item => item.ADBQty),
              backgroundColor: '#fff',
              fill: false,
              borderColor: '#3285a8',
              tension: 0.4, 
            },
            {
              label: 'Pozapaliwowe',
              data: data.length !== 0 && data.map(item => item.PPQty),
              backgroundColor: '#fff',
              fill: false,
              borderColor: '#a8a232',
              tension: 0.4, 
            },
          ],
    };
    return (
        <div className="raportTimeLine">
            <Line options={chartOptions} data={chartData}/>
        </div>
    )
}