export default function TableRow(props) {
    const data = props.data;
    const products = [
        { item: 4243, name: 'ON', color: '#252525' },
        { item: 4241, name: 'PB95', color: '#49a665' },
        { item: 4244, name: 'LPG', color: '#a64949' },
        { item: 4245, name: 'AdBlue', color: '#496da6' }
    ];
    const findProductName = (item) => {
        const product = products.find(product => product.item === item);
        return product ? product.name : 'PPaliw';
    };
    const findProductColor = (item) => {
        const product = products.find(product => product.item === item);
        return product ? product.color : '#252525';
    };

    const processedProducts = {};
    return data && data.sorted.map(record => {
        const productName = findProductName(record.items);
        const productColor = findProductColor(record.items);
        if (processedProducts[productName]) {
            return (
                <tr key={record.items + ' ' + record.price + ' ' + record.totalQty}>
                    <td><p style={{borderLeft: `3px solid ${productColor}`, margin: '.1vw 0', padding: '0 0 0 1.5vw', boxSizing: 'border-box'}}>{productName}</p></td>
                    <td><p style={{textAlign: 'right'}}>{(record.price).toFixed(2)} zł</p></td>
                    <td><p style={{textAlign: 'right'}}>{(record.totalQty).toFixed(2)} l</p></td>
                    <td><p style={{textAlign: 'right'}}>{(record.netto).toFixed(2)} zł</p></td>
                </tr>
            );
        } else {
            processedProducts[productName] = true;
            return (
                <tr key={record.items + ' ' + record.price + ' ' + record.totalQty} style={{ backgroundColor: '#e3e3e3' }}>
                    <td style={{borderLeft: `3px solid ${productColor}`, margin: '.1vw 0', padding: '0 0 0 1vw', boxSizing: 'border-box'}}><p>{productName}</p></td>
                    <td><p style={{textAlign: 'right'}}>{(record.price).toFixed(2)} zł</p></td>
                    <td><p style={{textAlign: 'right'}}>{(record.totalQty).toFixed(2)} l</p></td>
                    <td><p style={{textAlign: 'right'}}>{(record.netto).toFixed(2)} zł</p></td>
                </tr>
            );
        }
    });
};