export default function ShopNumbers(props) {
    function toPLN(number) {
        const formattedNumber = number.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedNumber;
    };

    function shortenString(str) {
        if (str.length > 12) {
          return str.slice(0, 20) + '...';
        }
        return str;
    };

    const proB = ((props.numbers.saleProfit * 100) / props.numbers.saleIncome).toFixed(2);
    const proA = ((props.preNumbers.saleProfit * 100) / props.preNumbers.saleIncome).toFixed(2);

    const volDif = (100 - ((props.preNumbers.saleVolume * 100) / props.numbers.saleVolume)).toFixed(2);
    const incDif = (100 - ((props.preNumbers.saleIncome * 100) / props.numbers.saleIncome)).toFixed(2);
    const proDif = (100 - ((props.preNumbers.saleProfit * 100) / props.numbers.saleProfit)).toFixed(2);

    const avgBasket = toPLN(props.numbers.saleBrutto / props.numbers.saleVolume);
    const avgPreBasket = toPLN(props.preNumbers.saleBrutto / props.preNumbers.saleVolume);

    const product = props.numbers.product;

    return (
        <div className="shopNumbers">
            <div className="shopNumber">
                <p>Wolumen sprzedaży</p>
                <h4>{props.numbers.saleVolume} <span>szt.</span></h4>
                <h6><span>Top produkt</span> {shortenString(product.name)} - {product.qty} szt.</h6>
                <h6><span>Tydzień temu</span> {props.preNumbers.saleVolume} szt. <span style={volDif < 0 ? {color: 'red'} : {color: 'green'}}>{volDif}%</span></h6>
            </div>
            <div className="shopNumber">
                <p>Sprzedaż netto</p>
                <h4>{toPLN(props.numbers.saleIncome)}</h4>
                <h6><span>Średni koszyk brutto</span> {avgBasket} <span style={avgBasket > avgPreBasket ? {color: 'red'} : {color: 'green'}}>{avgPreBasket}</span></h6>
                <h6><span>Tydzień temu</span> {toPLN(props.preNumbers.saleIncome)} <span style={incDif < 0 ? {color: 'red'} : {color: 'green'}}>{incDif}%</span></h6>
            </div>
            <div className="shopNumber">
                <p>Zysk operacyjny</p>
                <h4>{toPLN(props.numbers.saleProfit)}</h4>
                <h6><span>Śr marża jedn</span> {proB}% <span style={incDif < 0 ? {color: 'red'} : {color: 'green'}}>{(proB - proA).toFixed(2)}</span></h6>
                <h6><span>Tydzień temu</span> {toPLN(props.preNumbers.saleProfit)} <span style={proDif < 0 ? {color: 'red'} : {color: 'green'}}>{proDif}%</span></h6>
            </div>
        </div>
    )
}