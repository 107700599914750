import '../styles/fuelstorage.css';
import GetStorage from '../components/fuel storage/GetStorage';

export default function FuelStorage() {
    return (
        <div className="fuelStoragePage">
            <GetStorage/>   
        </div>
    )
}
