export default function FuelDeliResults(props) {
    const data = props.data;
    const dataLength = data.data.length;
    const FuelDeliRow = () => {
        const products = [ { item: 4243, name: 'ON' }, { item: 4241, name: 'PB95' }, { item: 4244, name: 'LPG' }, { item: 4245, name: 'AdBlue' } ];
        const findProductName = (item) => {
            const product = products.find(product => product.item === item);
            return product && product.name;
        };
        const sort = data.data.slice().sort((a, b) => {
            return new Date(b.DATAPOWSTANIA) - new Date(a.DATAPOWSTANIA)
        });
        return data && sort.map(record => 
            <tr key={record._id}>
                <td><p>{`${new Date(record.DATAPOWSTANIA).getDate() < 10 ? '0' + new Date(record.DATAPOWSTANIA).getDate() : new Date(record.DATAPOWSTANIA).getDate() }.${(new Date(record.DATAPOWSTANIA).getMonth() + 1) < 10 ? '0' + (new Date(record.DATAPOWSTANIA).getMonth() + 1) : new Date(record.DATAPOWSTANIA).getMonth() +1 }.${new Date(record.DATAPOWSTANIA).getFullYear()}`}</p></td>
                <td><p>{`${new Date(record.DATAPOWSTANIA).getHours() < 10 ? '0' + new Date(record.DATAPOWSTANIA).getHours() : new Date(record.DATAPOWSTANIA).getHours() }:${new Date(record.DATAPOWSTANIA).getMinutes() < 10 ? '0' + new Date(record.DATAPOWSTANIA).getMinutes() : new Date(record.DATAPOWSTANIA).getMinutes() }`}</p></td>
                <td><p>{findProductName(record.POZTOWID)}</p></td>
                <td><p>{record.MAGNAZWA}</p></td>
                <td><p>{(record.ILOSCPOCZATKOWAJS).toFixed(2)} l</p></td>
                <td><p>{record.ILOSCZOSTALOJS === 0 ? (record.ILOSCPOCZATKOWAJS).toFixed(2) : (record.ILOSCZOSTALOJS).toFixed(2)} l</p></td>
            </tr>
        );
    }
    return (
        <div className="fuelDeliResults">
            <h6>Znaleziono <b>{dataLength || 0}</b> wyników</h6>
            <div className="fuelTable">
                <table>
                    <thead>
                        <tr>
                            <th>Dzień</th>
                            <th>Godzina</th>
                            <th>Produkt</th>
                            <th>Zbiornik</th>
                            <th>Ilość przyjęta</th>
                            <th>Pozostało w zbiorniku</th>
                        </tr>                    
                    </thead>
                    <tbody><FuelDeliRow/></tbody>
                </table>
            </div>            
        </div>
    )
}