import '../../styles/raport.css';
import RaportSummary from './RaportSummary';
import RaportDetails from './RaportDetails';
import RaportHours from './RaportHours';
import RaportTimeLine from './RaportTimeLine';

export default function Raport(props) {
    return (
        <div className='raport'>
            <h1>Raport <span>sprzedaży</span></h1>
            {props.data && <RaportDetails data={props.data.query}/>}
            {props.data && <RaportSummary data={props.data.summary}/>}
            {/* {props.data && <RaportHours data={props.data}/>} */}
            {props.data && <RaportTimeLine data={props.data.dateFilter}/>}
        </div>
    )
}