import '../../styles/shopData.css';
import ShopNumbers from "./ShopNumbers";
import ShopHours from "./ShopHours";
import ShopGroups from './ShopGroups';
import ShopProductGroups from './ShopProductGroups';

export default function ShopData(props) {
    return (
        <>
            {props.data && <ShopNumbers numbers={props.data.numbers} preNumbers={props.data.preNumbers}/>}
            <div className="shopCharts">
                <div className="shopLeft">
                    {props.data && <ShopGroups data={props.data.numbers.chart}/>}
                </div>
                <div className="shopRight">
                    {props.data && <ShopHours data={props.data.numbers.hourChart}/>}
                </div>
            </div>
            <div className='shopGroups'>
                {props.data && <ShopProductGroups data={props.data.numbers.chart} docs={props.data.numbers.docs}/>}
            </div>
        </>
    )
}