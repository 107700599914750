import { useState, useEffect } from "react";
import axios from 'axios';
import '../../styles/fuelDeli.css';
import Loading from '../Loading';
import FuelDeliResults from "./FuelDeliResults";

export default function FuelDeliverie() {
    const [ data, setData ] = useState();
    const [ product, setProduct ] = useState('All');
    const [ amountFrom, setAmountFrom ] = useState(0);
    const [ amountTo, setAmountTo ] = useState(100000);
    const today = `${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1 ) : new Date().getMonth() + 1 }-${new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()}`;
    const starting = `${new Date(today).getFullYear()}-${(new Date(today).getMonth() + 1) < 10 ? '0' + (new Date(today).getMonth() + 1 ) : new Date(today).getMonth() + 1 }-${(new Date(today).getDate() - 7) < 10 ? ('0' + (new Date(today).getDate() -7)) : (new Date(today).getDate() -7 )}`;    
    const [ startDate, setStartDate ] = useState(new Date(starting));
    const [ stopDate, setStopDate ] = useState(new Date(today));
    const handleChange = (value, setter) => { setter(value) }
    useEffect( () => {
        async function getDeliveries() {
            try {
                axios({
                    method: 'post',
                    url: "https://ax-app-746b7eed1e15.herokuapp.com/api/fuel-delivery",
                    // url: 'https://ax-app--manager-7a0e131a3acf.herokuapp.com/api/fuel-delivery',
                    data: { product, amountFrom, amountTo, startDate, stopDate }
                }).then(function(response) {
                    setData(response)
                })
            } catch(error) { console.log(error) }
        };
        getDeliveries();
    }, [product, amountFrom, amountTo, startDate, stopDate] );
    return (
        <div className="fuelDelivery">
            <h1>Dostawy paliw</h1>            
            <div className="search">
                <label>
                    <p>Produkt:</p>
                    <select defaultValue="All" onChange={e => handleChange(e.target.value, setProduct)}>
                        <option value="All">Wszystkie</option>
                        <option value="4243">ON</option>
                        <option value="4241">PB95</option>
                        <option value="4244">LPG</option>
                        <option value="4245">AdBlue</option>
                    </select>
                </label>
                <label>
                    <p>Ilość od:</p>
                    <input type="number" onChange={e => handleChange(e.target.value, setAmountFrom)}/>
                </label>
                <label>
                    <p>Ilość do:</p>
                    <input type="number" onChange={e => handleChange(e.target.value, setAmountTo)}/>
                </label>
                <label>
                    <p>Data od:</p>
                    <input type="date" defaultValue={starting} onChange={e => handleChange(e.target.value, setStartDate)}/>
                </label>
                <label>
                    <p>Data do:</p>
                    <input type="date" defaultValue={today} onChange={e => handleChange(e.target.value, setStopDate)}/>
                </label>
            </div>
            { !data ? <Loading/> : <FuelDeliResults data={data}/> }
        </div>   
    )
}