import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );

export default function RaportCircleChart(props) {
    const data = props.data;
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { position: 'top', }, title: { display: false, } },
    };
    const chartData = {
        labels: data && data.map(item => item.label),
        datasets: [{
            label: 'Wolumen',
            data: data && data.map(item => item.value),
            backgroundColor: [
                '#252525',
                '#48965d',
                '#964850',
                '#415b9c',
                '#878787'
            ],
            hoverOffset: 10
        }]
    };
    return (
        <Doughnut options={chartOptions} data={chartData}/>
    )
};