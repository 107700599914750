import '../../styles/yestTable.css';
import YestTableRow from './YestTableRow';

export default function YestTable(props) {
    const data = props.data.data;
    return (
        <table>
            <thead>
                <tr>
                    <th style={{width: '15%'}}><p>Produkt</p></th>
                    <th><p>Cena brutto</p></th>
                    <th><p>Litraż</p></th>
                    <th><p>Sprzedaż netto</p></th>
                </tr>
            </thead>
            <tbody>
                {data && <YestTableRow data={data}/>}
            </tbody>
        </table>
    );
}
