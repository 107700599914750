import '../../styles/priceChart.css';
import Loading from '../Loading';
import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );

export default function PricesChart(props) {
    const data = props.data;
    const on = data[0];
    const pb = data[1];
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: { position: 'top', }, title: { display: false, } },
    };

    const newPB = [];
    const cal = on.map(item => `${new Date(item.DATAPOWSTANIA).getDate() < 10 ? '0' + new Date(item.DATAPOWSTANIA).getDate() : new Date(item.DATAPOWSTANIA).getDate()}.${(new Date(item.DATAPOWSTANIA).getMonth() + 1) < 10 ? '0' + (new Date(item.DATAPOWSTANIA).getMonth() +1) : new Date(item.DATAPOWSTANIA).getMonth() +1}`).reverse();
    const a = pb.map(item => `${new Date(item.DATAPOWSTANIA).getDate() < 10 ? '0' + new Date(item.DATAPOWSTANIA).getDate() : new Date(item.DATAPOWSTANIA).getDate()}.${(new Date(item.DATAPOWSTANIA).getMonth() + 1) < 10 ? '0' + (new Date(item.DATAPOWSTANIA).getMonth() +1) : new Date(item.DATAPOWSTANIA).getMonth() +1}`).reverse();

    for (const doc in cal) {
        const filter = pb.filter(item => `${new Date(item.DATAPOWSTANIA).getDate() < 10 ? '0' + new Date(item.DATAPOWSTANIA).getDate() : new Date(item.DATAPOWSTANIA).getDate()}.${(new Date(item.DATAPOWSTANIA).getMonth() + 1) < 10 ? '0' + (new Date(item.DATAPOWSTANIA).getMonth() +1) : new Date(item.DATAPOWSTANIA).getMonth() +1}` === cal[doc])
        a.includes(cal[doc]) ? newPB.push(filter[0].CENASJS) : newPB.push(newPB[doc - 1]);
    };

    const chartData = {
        labels: data && on.map(item => `${new Date(item.DATAPOWSTANIA).getDate() < 10 ? '0' + new Date(item.DATAPOWSTANIA).getDate() : new Date(item.DATAPOWSTANIA).getDate()}.${(new Date(item.DATAPOWSTANIA).getMonth() + 1) < 10 ? '0' + (new Date(item.DATAPOWSTANIA).getMonth() +1) : new Date(item.DATAPOWSTANIA).getMonth() +1}`).reverse(),
        datasets: [
          {
            label: 'ON',
            data: data && on.map(item => item.CENASJS).reverse(),
            backgroundColor: '#000000EA',
            fill: false,
            borderColor: '#00000091',
            tension: 0.4, 
          },
          {
            label: 'PB95',
            data: data && newPB.map(item => item),
            backgroundColor: '#00D438',
            fill: false,
            borderColor: '#00D43887',
            tension: 0.4, 
          }
        ],
    };
    return (
        <div className="pricesChart">
            { !data ? <Loading/> : <Line options={chartOptions} data={chartData}/> }
        </div>
    )
}