import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from "./contexts/userContext";
import './styles/app.css';
import Home from "./pages/Homepage.js";
import Login from "./pages/Login.js";
import PrivateRoute from "./pages/PrivateRoute";
import Raports from "./pages/Raports";
import Prices from "./pages/Prices";
import FuelDeliveries from "./pages/FuelDeliveries";
import FuelStorage from "./pages/FuelStorage.js";
import Shop from "./pages/Shop.js";
import Safety from "./pages/Safety.js";
import PageNotFound from "./pages/PageNotFound.js";
import ShopGroupDetails from "./components/shop/ShopGroupDetails.jsx";
 
function App() {
 return (
   <BrowserRouter>
     <UserProvider>
       <Routes>
         <Route exact path="/login" element={<Login/>} />
         <Route element={<PrivateRoute/>}>
           <Route exact path="/" element={<Home/>} />
           <Route exact path="/raports" element={<Raports/>} />
           <Route exact path="/prices" element={<Prices/>} />
           <Route exact path="/fuel-deliveries" element={<FuelDeliveries/>} />
           <Route exact path="/fuel-storage" element={<FuelStorage/>} />
           <Route exact path="/shop-raport" element={<Shop/>}/>
           <Route exact path="/shop-raport/group/:itemName" element={<ShopGroupDetails/>}/>
           <Route exact path="/app-safety" element={<Safety/>}/>
           <Route path="*" element={<PageNotFound/>}/>
         </Route>
       </Routes>
     </UserProvider>
   </BrowserRouter>
 );
}
 
export default App;