export default function ShopDetailTr(props) {
    function toPLN(number) {
        const formattedNumber = number.toLocaleString('pl-PL', {
            style: 'currency',
            currency: 'PLN',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedNumber;
    };
    function formatDate(date) {
        const data = new Date(date)
        const year = data.getFullYear();
        const month = String(data.getMonth() + 1).padStart(2, '0');
        const day = String(data.getDate()).padStart(2, '0');
        const hour = String(data.getHours()).padStart(2, '0');
        const minutes = String(data.getMinutes()).padStart(2, '0');
        return `${hour}:${minutes} ${day}.${month}.${year}`
    };
    return props && props.data.map(item =>
        <tr key={item.productName + item.date}>
            <td><p>{item.type}</p></td>
            <td><p style={{textAlign: 'left'}}>{formatDate(item.date)}</p></td>
            <td><p style={{textAlign: 'left'}}>{item.productName}</p></td>
            <td><p>{item.qty}</p></td>
            <td><p>{toPLN(item.in.itemPurchasePrice)}</p></td>
            <td><p>{toPLN(item.netto)}</p></td>
            <td><p>{toPLN(item.brutto)}</p></td>
            <td><p>{(((item.netto - (item.in.itemPurchasePrice * item.qty)) / item.netto) * 100).toFixed(2)}% | {toPLN((item.netto) - (item.in.itemPurchasePrice * item.qty))}</p></td>
            <td><p>{toPLN(item.mag.regularPrice)}</p></td>
        </tr>
    )
}