import Loading from "../Loading";
import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import '../../styles/newChart.css';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );

export default function DailyChart(props) {
  function toPLN(number) {
      const formattedNumber = number.toLocaleString('pl-PL', {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
      });
      return formattedNumber;
  };
  const Products = () => {
    return props && props.fuels.map(item => (
      <div key={item.code}>
        <div>
          <h6>{item.name}</h6>
          <h2>{(item.qty.toFixed(2))} l</h2>
        </div>
        <div className="details">
          <h5 className="margin"><span>Marża</span> {toPLN(item.profit / item.qty)}</h5>
          <h5 className="prevQty" style={((item.qty * 100) / props.prevFuels.filter(el => el.code === item.code).map(rec => rec.qty)) - 100 < 0 ? {color: 'red'} : {color: 'green'}}><span>Tydzień temu</span> {(((item.qty * 100) / props.prevFuels.filter(el => el.code === item.code).map(rec => rec.qty)) - 100).toFixed(2)}%</h5>
        </div>
      </div>)
    )
  };

  const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: { legend: { position: 'top', }, title: { display: false } },
  };
  const chartData = {
      labels: props.days.length !== 0 && props.days.map(item => item.label),
      datasets: [
        {
          label: 'Paliwa',
          data: props.days.length !== 0 && props.days.map(item => item.qty),
          backgroundColor: '#a3a3a3',
          fill: false,
          borderColor: '#a3a3a3',
          tension: 0.4, 
        },
        {
          label: 'ON',
          data: props.days.length !== 0 && props.days.map(item => item.onQty),
          backgroundColor: '#000000EA',
          fill: false,
          borderColor: '#00000091',
          tension: 0.4, 
        },
        {
          label: 'PB95',
          data: props.days.length !== 0 && props.days.map(item => item.pb95Qty),
          backgroundColor: '#58b06f',
          fill: false,
          borderColor: '#58b06f',
          tension: 0.4, 
        },
        {
          label: 'LPG',
          data: props.days.length !== 0 && props.days.map(item => item.lpgQty),
          backgroundColor: '#e85656',
          fill: false,
          borderColor: '#e85656',
          tension: 0.4, 
        },
      ],
  };

  return (
    <div className="chartContainer">
      <div className="products">
        {!props ? <Loading/> : <Products/>}
      </div>
      <div className="theChart">
        {!props ? <Loading/> : <Line options={chartOptions} data={chartData}/>}
      </div>
    </div>
  )
}