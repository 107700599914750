import '../../styles/raportStatic.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../Loading';
import Raport from './Raport';
import Select from 'react-select';

export default function RaportStatic() {
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 7);
    yesterday.setHours(0,0,0);
    const docs = [
        { value: 'Paragony', label: 'Paragony' },
        { value: 'DokFV', label: 'Faktury VAT' },
        { value: 'DokWZ', label: 'WZ-ki' },
    ];
    const products = [
        { value: [4243, 4241, 4244, 4245], label: 'Paliwa'},
        { value: 4243, label: 'Olej napędowy'},
        { value: 4241, label: 'Benzyna 95'},
        { value: 4244, label: 'Autogaz LPG'},
        { value: 4245, label: 'AdBlue'},
        { value: 'PP', label: 'Pozapaliwowe'},
    ];

    const [ query, setQuery ] = useState({
        start: yesterday,
        stop: today,
        docs: [docs[0], docs[1], docs[2]].map(item => item.value),
        product: [products[1], products[2], products[3], products[4], products[5]].map(item => item.value)
    });

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hour}:${minutes}`
    };

    useEffect( () => {
        async function getData() {
            try {
                setLoading(true);
                axios({
                    method: 'post',
                    url: 'https://ax-app-746b7eed1e15.herokuapp.com/api/new-raport',
                    // url: 'http://localhost:4000/api/new-raport',
                    data: query
                }).then(function(response) {
                    setData(response);
                    setLoading(false);
                });
            } catch(error) { console.log(error) }
        };
        getData();
    }, [ query ] );

    return (
        <div className="raportStatic">
            <h1>Raporty <span>sprzedaży</span></h1>
            <div className="search">
                <form>
                    <div className='leftCol'>
                        <label>
                            <p>Od</p>
                            <input 
                                type="datetime-local" 
                                name="start" 
                                defaultValue={formatDate(yesterday)} 
                                onChange={ e => setQuery( prevQuery => ({ ...prevQuery, [e.target.name]: e.target.value }))}
                            />
                        </label>
                        <label>
                            <p>Do</p>
                            <input 
                                type="datetime-local" 
                                name="stop" 
                                defaultValue={formatDate(today)} 
                                onChange={ e => setQuery( prevQuery => ({ ...prevQuery, [e.target.name]: e.target.value }))}
                            />
                        </label>
                    </div>
                    <div className='rightCol'>
                        <label style={{width: '100%'}}>
                            <p>Transakcje</p>
                            <Select 
                                defaultValue={[docs[0], docs[1], docs[2]]}
                                options={docs}
                                isMulti
                                name="docs"
                                className="select-docs"
                                classNamePrefix="select"
                                onChange={ e => setQuery(prev => ({...prev, docs: e.map(item => item.value)})) }
                            />
                        </label>
                        <label style={{width: '100%'}}>
                            <p>Produkty</p>
                            <Select 
                                defaultValue={[products[1], products[2], products[3], products[4], products[5]]}
                                options={products}
                                isMulti
                                name="product"
                                className="select-products"
                                classNamePrefix="select"
                                onChange={ e => setQuery(prev => ({...prev, product: e.map(item => item.value)})) }
                            />
                        </label>
                    </div>
                </form>
            </div>
            {loading ? <Loading/> : <Raport data={data.data}/>}
        </div>
    )
}