import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );

export default function ShopGroups(props) {
    const data = props.data;
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: { legend: false, title: { display: false, } },
    };
    const chartData = {
        labels: data && data[0].sort((a, b) => b.qty - a.qty).map(item => item.name),
        datasets: [{
            label: 'Ilość',
            data: data && data[0].sort((a, b) => b.qty - a.qty).map(item => item.qty),
            backgroundColor: [
                '#081C15',
                '#1B4332',
                '#2D6A4F',
                '#40916C',
                '#52B788',
                '#74C69D',
                '#95D5B2',
                '#B7E4C7',
                '#D8F3DC',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
            ],
            hoverOffset: 10
        },
        {
            label: 'Sprzedaż brutto',
            data: data && data[0].sort((a, b) => b.qty - a.qty).map(item => item.brutto),
            backgroundColor: [
                '#081C15',
                '#1B4332',
                '#2D6A4F',
                '#40916C',
                '#52B788',
                '#74C69D',
                '#95D5B2',
                '#B7E4C7',
                '#D8F3DC',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
            ],
            hoverOffset: 10
        },
        {
            label: 'Marża kwotowa',
            data: data && data[0].sort((a, b) => b.qty - a.qty).map(item => item.profit),
            backgroundColor: [
                '#081C15',
                '#1B4332',
                '#2D6A4F',
                '#40916C',
                '#52B788',
                '#74C69D',
                '#95D5B2',
                '#B7E4C7',
                '#D8F3DC',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
                '#f0f7f2',
            ],
            hoverOffset: 10
        },
        ]
    };
    return (
        <Doughnut options={chartOptions} data={chartData}/>
    )
}