import { useNavigate, useLocation } from 'react-router-dom';
import ShopGroupDetailsSummaries from './ShopGroupDetailsSummaries';
import ShopDetailTr from './ShopDetailTr';
import '../../styles/shopRaportDetails.css';

export default function ShopGroupDetails() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        navigate('/shop-raport');
    };
    const { data, docs } = location.state;

    return (
        <div className="shopGroupDetails">
            <div className='header'>
                <button onClick={handleClick}>
                    <svg viewBox="0 0 477.175 477.175">
                        <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/>
                    </svg>
                </button>
                <h2>{data.name}</h2>
                {data && <ShopGroupDetailsSummaries data={data}/>}
            </div>
            <div className='detailTable'>
                <table>
                    <thead>
                        <tr>
                            <th>Dokument</th>
                            <th>Data</th>
                            <th style={{width: '20%'}}>Produkt</th>
                            <th>Ilość</th>
                            <th>Zakup netto</th>
                            <th>Sprzedaż netto</th>
                            <th>Sprzedaż brutto</th>
                            <th>Marża</th>
                            <th>Cena regularna</th>
                        </tr>
                    </thead>
                    <tbody>
                        {docs && <ShopDetailTr data={docs}/>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}